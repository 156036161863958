import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import 'sanitize.css';
import merge from 'lodash/merge';

import GlobalStyles from './global-styles';

import customThemeContext from './customThemeContext';
import theme from './theme';
import Box from '../Box';

export default (props) => {
  const [joinedTheme, setTheme] = useState(theme);
  const overrideTheme = (newTheme) => setTheme(merge({}, theme, newTheme));
  return (
    <ThemeProvider theme={joinedTheme}>
      <customThemeContext.Provider value={{ overrideTheme }}>
        <div>
          <Box fontSize={[14, null, 16]} {...props} />
          <GlobalStyles />
        </div>
      </customThemeContext.Provider>
    </ThemeProvider>
  )
};
