/**
 * Create the store with dynamic reducers
 */

import { createStore, compose, applyMiddleware  } from 'redux';
import { responsiveStoreEnhancer } from 'redux-responsive';
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import immutableTransform from 'redux-persist-transform-immutable'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

import createReducer from './reducers';

import surveySaga from './surveySaga'

export default function configureStore(initialState = {}) {
  const sagaMiddleware = createSagaMiddleware()

  const middleWares = [
    sagaMiddleware,
  ];

  const enhancers = [
    applyMiddleware(...middleWares),
    responsiveStoreEnhancer,
  ];

  if (typeof window !== 'undefined') {
    const { firebaseEnhancer } =require('../services/firebase');
    enhancers.push(firebaseEnhancer)
  }

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    (process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  /* eslint-enable */

  const persistConfig = {
    transforms: [immutableTransform()],
    key: 'root',
    storage,
    whitelist: ['survey']
  }

  const persistedReducer = persistReducer(persistConfig, createReducer())

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(...enhancers)
  );

  surveySaga.map(sagaMiddleware.run);

  // Extensions
  store.injectedReducers = {}; // Reducer registry

  return { store, persistor: persistStore(store) };
}
