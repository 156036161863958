import React from 'react'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'

import ThemeProvider from './src/components/ThemeProvider';
import creatStore from './src/stores/createStore';

const { store, persistor } = creatStore();

export default ({ element }) => (
  <Provider store={store}>
    {typeof window === 'undefined' ? (
      <ThemeProvider>{element}</ThemeProvider>
    ) : (
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>{element}</ThemeProvider>
      </PersistGate>
    )}
  </Provider>
)
