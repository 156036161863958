import { call, put, select, takeEvery } from 'redux-saga/effects'
import pickBy from 'lodash/pickBy';
import isArray from 'lodash/isArray';
import mapValues from 'lodash/mapValues';
import set from 'lodash/set';
import { Map } from 'immutable';
import sanitize from 'sanitize-html';

import { firebase } from '../services/firebase';

import {
  SET_COMPLETED,
  // SECTION_FINISHED,
  selectSurvey,
  resetSurvey,
} from './surveyStore';
import sentry from '../utils/sentry';

const sanitizeAnsCleanUndefined = (value) => {
  if (isArray(value)) {
    return value.map(sanitizeAnsCleanUndefined);
  }
  return value === undefined ? null : sanitize(value);
}

const parseKey = (key) => {
  const [, name, pos] = /([^\[]+)\[([^\]]+)\]/.exec(key);
  return [name, pos];
}

function* saveToDatabase({ payload: { slug } }) {
  const data = yield select(selectSurvey(slug));
  const values = mapValues(pickBy(data.answers.toJS(), (v) => v !== undefined), sanitizeAnsCleanUndefined);
  if (Map.isMap(data.others)) {
    data.others.forEach((value, key) => set(values, parseKey(key), value));
  }
  if (Map.isMap(data.timers)) {
    values.timers = data.timers.toJS();
  }
  if (Map.isMap(data.source)) {
    values.source = data.source.toJS();
  }
  values.slug = slug;
  let ref = firebase.firestore().collection('answers');
  const updating = data.updating;
  if (updating) {
    ref = ref.doc(updating);
    values.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
  } else {
    values.doneAt = firebase.firestore.FieldValue.serverTimestamp();
  }

  try {
    if (updating) {
      yield call([ref, ref.update], values);
    } else {
      yield call([ref, ref.add], values);
    }
    yield put(resetSurvey({ slug }));
  } catch (e) {
    console.error(e);
    sentry.captureException(e);
  }
}

function* surveySaga() {
  yield takeEvery(SET_COMPLETED, saveToDatabase);
}

// function* checkSection({ payload: { slug } }) {
//   const data = yield select(selectSurvey(slug));
//   const values = pickBy(data.answers.toJS(), (v) => v !== undefined);
//   data.others.forEach((value, key) => set(values, parseKey(key), value));
// }

// function* sectionSaga() {
//   yield takeEvery(SECTION_FINISHED, checkSection);
// }

export default [
  surveySaga,
  // sectionSaga,
];
