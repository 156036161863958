// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-formtest-js": () => import("./../../../src/pages/formtest.js" /* webpackChunkName: "component---src-pages-formtest-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-edit-js": () => import("./../../../src/templates/edit.js" /* webpackChunkName: "component---src-templates-edit-js" */),
  "component---src-templates-general-js": () => import("./../../../src/templates/general.js" /* webpackChunkName: "component---src-templates-general-js" */),
  "component---src-templates-questions-index-js": () => import("./../../../src/templates/questions/index.js" /* webpackChunkName: "component---src-templates-questions-index-js" */),
  "component---src-templates-reset-js": () => import("./../../../src/templates/reset.js" /* webpackChunkName: "component---src-templates-reset-js" */)
}

