import { fromJS, Map } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { createContext } from 'react';
import { createSelector } from 'reselect';
import toPairs from 'lodash/toPairs';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';

export const SET_SOURCE = 'survey/SET_SOURCE';
export const SET_ANSWER = 'survey/SET_ANSWER';
export const SET_TIMER = 'survey/SET_TIMER';
export const SECTION_FINISHED = 'survey/SECTION_FINISHED';
export const SET_COMPLETED = 'survey/SET_COMPLETED';
export const SET_OTHER_VALUE = 'survey/SET_OTHER_VALUE';
export const READ_SURVERY = 'survey/READ_SURVERY';
export const RESET_SURVEY = 'survey/RESET_SURVEY';
export const FORCE_RESET = 'survey/FORCE_RESET';

export const setAnswer = createAction(SET_ANSWER);
export const setTimer = createAction(SET_TIMER);
export const sectionFinished = createAction(SECTION_FINISHED);
export const setCompleted = createAction(SET_COMPLETED);
export const resetSurvey = createAction(RESET_SURVEY);
export const forceReset = createAction(FORCE_RESET);
export const setOtherValue = createAction(SET_OTHER_VALUE);
export const readSurvey = createAction(READ_SURVERY);
export const setSource = createAction(SET_SOURCE);

const initialState = fromJS({
  answers: {},
  sections: {},
  completed: {},
  others: {},
  timers: {},
});

const resetByIndex = index => map => {
  if (!Map.isMap(map)) return map;
  return map.reduce((res, val, key) => {
    const id = key.split('-')[0]
    if (id >= index) {
      return res.delete(key);
    }
    return res;
  }, map);
}

const reducer = handleActions({
  [SET_SOURCE]: (state, { payload }) => state.set('source', fromJS(payload)),
  [SET_ANSWER]: (state, { payload: { slug, values } }) => state.mergeIn(['answers', slug], fromJS(values)),
  [SECTION_FINISHED]: (state, { payload: { slug, section } }) => state.setIn(['sections', slug], section),
  [SET_COMPLETED]: (state, { payload: { slug } }) => state.setIn(['completed', slug], true),
  [SET_OTHER_VALUE]: (state, { payload: { slug, name, value } }) => state.setIn(['others', slug, name], value),
  [SET_TIMER]: (state, { payload: { slug, name, value } }) => state.setIn(['timers', slug, name], value),
  [RESET_SURVEY]: (state, { payload: { slug } }) => initialState
    .reduce((res, value, key) => key === 'completed' ? res.setIn([key, slug], true) : res.deleteIn([key, slug]), state),
  [FORCE_RESET]: (state, { payload: { slug, index } }) => {
    if (typeof index === 'number') {
      const currentSection = state.getIn(['sections', slug]);
      const clearAfter = Math.min([currentSection, index]);
      return state
        .setIn(['sections', slug], clearAfter - 1)
        .updateIn(['answers', slug], resetByIndex(index))
        .updateIn(['others', slug], resetByIndex(index))
        .updateIn(['timers', slug], resetByIndex(index));
    }
    return initialState
      .reduce((res, value, key) => res.deleteIn([key, slug]), state)
  },
  [READ_SURVERY]: (state, { payload: { slug, answers, answerId } }) => {
    const syncedState = toPairs(answers).reduce((s, [key, value]) => {
      const [index] = key.split('-');
      if (isNumber(+index)) {
        if (isArray(value)) {
          let ss = s;
          const other = value.findIndex((str) => str && str !== 'true' && str !== 'false' && isNaN(+str));
          if (other > -1) {
            ss = ss.setIn(['others', slug, key], value)
            value[other] = true;
          }
          return ss.setIn(['answers', slug, key], fromJS(value));
        } else {
          return s.setIn(['answers', slug, key], value);
        }
      }
      return s;
    }, state)
    return syncedState
      .setIn(['timers', slug], fromJS(answers.timers))
      .set('updates', answerId);
  },
}, initialState);

export default reducer;

export const mapDispatchToProps = (dispatch) => bindActionCreators({
  setAnswer,
  sectionFinished,
  setCompleted,
  setTimer,
  setOtherValue,
  readSurvey,
  setSource,
}, dispatch);

const selectSurveyState = (state) => state.survey;

export const selectSurvey = (slug) => createSelector(selectSurveyState, (state) => ({
  answers: state.getIn(['answers', slug]),
  finishedSection: state.getIn(['sections', slug]),
  others: state.getIn(['others', slug]),
  timers: state.getIn(['timers', slug]),
  source: state.get('source'),
  updating: state.get('updates'),
}));

export const selectModules = (slug, section) => createSelector(selectSurveyState, (state) => state.getIn(['modules', slug, section]));
export const selectCompleted = (slug) => createSelector(selectSurveyState, (state) => ({
  completed: state.getIn(['completed', slug]),
}));

export const withConnect = (slug) => connect(selectSurvey(slug), mapDispatchToProps);

export const ReduxContext = createContext({
  setOtherValue: () => {},
  setTimer: () => {},
  others: {},
  timers: {},
});

export const withReduxContext = (SubComp) => (props) => (
  <ReduxContext.Consumer>
    {(context) => <SubComp {...props} {...context} />}
  </ReduxContext.Consumer>
);
