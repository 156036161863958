import * as Sentry from '@sentry/browser';

let s = Sentry;

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: 'https://a75786710f5a4080aeb3e164dc9f6291@sentry.io/1468123' });
} else {
  s = {
    captureException: () => {},
  }
}

export default s;
